import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/Layout'
import { Box, Button, Flex, Heading, Image, List, ListItem, Grid, Stack, Text } from '@chakra-ui/react'
import css from '../components/slices/wysiwyg.module.scss'
import NewsletterModalBook from '../components/NewsletterModalBook'
import ReactGA from 'react-ga'
import { FormattedMessage } from 'react-intl'

ReactGA.initialize( process.env.GA_ID )


const BookReadMore = ({ data, pageContext }) => {
  const pageContent = data.prismic.allBooks.edges[0]
  if (!pageContent) return null
  const page = pageContent.node
  const book = pageContent.node

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  const posts = data.prismic.allPosts.edges
  const books = data.prismic.books.edges

  const [isNewsletterFormVisible, setNewsletterFormVisible] = useState(false);
  const [readMoreVisible, setReadMoreVisible] = useState(false)

  const handleReadMore = () => {
    ReactGA.event({
      category: 'Book',
      action: 'ReadMore',
      label: book.book_identifier
    });
    setReadMoreVisible(true)
  }

  if (page.pdf_document) {
    // console.log('PDF Url : ', page.pdf_document.url)
  }
  const CtaEmail = (props) => {
    return (
      <Box>
        <Button
          onClick={ () => { setNewsletterFormVisible( true ) }}
          fontFamily='Open Sans'
          fontSize='13px'
          textTransform='uppercase'
          color='white'
          bg='brand.orange'
          whiteSpace='pre-wrap'
          border='solid 1px'
          borderColor='brand.orange'
          w={props.w}
          _hover={{
            color: 'brand.orange',
            bg: 'white'
          }}
        >
          <FormattedMessage id='book.get.for.free' /> →
          </Button>
        <Text mt='.5rem' fontFamily='Open Sans' fontSize={{ base: '12px', lg: '13px' }}>
          <FormattedMessage id='lp.security.notice' />
        </Text>
      </Box>
    )
  }

  if (page) {
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
        hideNav={true}
      >
        <Helmet>
          {page.seo_title ?
            <title>{RichText.asText(page.seo_title)}</title>
            : null}
          {page.seo_description ?
            <meta name="description" content={RichText.asText(page.seo_description)} />
            : null}
          {page.sharing_image ?
            <meta property="og:image" content={page.sharing_image.url} />
            : null}
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        <NewsletterModalBook isOpen={ isNewsletterFormVisible } book={ book } handleClose = { () => { 
            setNewsletterFormVisible( false )
            // console.log( 'close')
          }} />
        <Box mx='auto' maxW='1000px' py={{ lg: '4rem' }} px={{ lg: '1rem' }}>
          <Grid
            templateColumns={{ lg: readMoreVisible ? '300px 1fr' : '400px 1fr' }}
            gridGap={{ base: '2rem', lg: '5rem' }}
          >
            <Box bg='gray.50' p='2rem' pt={{ lg: '4rem' }}>
            <Stack spacing='1rem' position='sticky' top='2rem'>
                <Image
                  // display={{ base: 'none', lg: 'block' }}
                  w={{ base:'80%', lg:'auto'}}
                  mx='auto'
                  objectFit='cover'
                  objectPosition='bottom'
                  src={page.cover.medium ? page.cover.medium.url : page.cover.url }
                  alt={page.cover.alt ? page.cover.alt : ''}
                />
                <CtaEmail w='100%' />
              </Stack>
            </Box>
            <Stack spacing="3rem" p={{ base: '2rem', lg: '0rem' }} pt={{ lg: '4rem' }} shouldWrapChildren={true}>
              <Heading>
                { book.lp_quote }
              </Heading>
              <Box
                fontSize='18px'
                mx='auto'
                maxW='1000px'
                py={{ lg: '4rem' }}
              >
                <Box position='relative'>
                  <Flex
                    position='absolute'
                    bottom='0'
                    left='0'
                    w='100%'
                    h='500px'
                    background='linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                    justifyContent="center"
                    alignItems='center'
                    display={readMoreVisible ? 'none' : 'flex'}
                  // opacity='.5'
                  >
                    <Button
                      onClick={() => { handleReadMore() }}
                      mt='3rem'
                      fontFamily='Open Sans'
                      fontSize='13px'
                      textTransform='uppercase'
                      color='white'
                      bg='brand.orange'
                      whiteSpace='pre-wrap'
                      border='solid 1px'
                      borderColor='brand.orange'
                      mt='15rem'
                      fontSize='16px'
                      letterSpacing='0.065rem'
                      boxShadow='md'
                      px='2rem'
                      _hover={{
                        color: 'brand.orange',
                        bg: 'white'
                      }}
                    >
                      <FormattedMessage id='post.read' />{' '}↓
                    </Button>
                  </Flex>
                  <Box
                    maxH={readMoreVisible ? 'none' : '400px'}
                    overflow='hidden'
                    className={css.wysiwyg}
                    mb='2rem'
                  >
                    {page.full_content ? 
                      <RichText render={page.full_content || []} />
                    : null}
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Box>
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query bookQueryReadMore($uid: String, $lang: String) {
  prismic {
    allBooks(
      uid: $uid,
      lang: $lang
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          lp_quote
          is_visible
          title
          book_identifier
          quote
          seo_title
          seo_description
          sharing_image
          cover
          cover_no_text
          intro
          content
          is_email_box_visible
          full_content
          pdf_document{
            _linkType
            ... on PRISMIC__FileLink{
              url
            }
          }
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }       
          body{
            ... on PRISMIC_BookBodyQuote{
              type
              label
              primary{
                title1
              }
            }
            #... on PRISMIC_BookBodyEmail_book_box{
            #  type
            #  label
            #  primary{
            #    text_intro
            #    book_identifier
            #  }
            #}
            ... on PRISMIC_BookBodyFull_width_banner{
              type
              label
              primary{
                banner_title
              }
            }
            ... on PRISMIC_BookBodyInfo_with_image{
              type
              label
              primary{
                text
                featured_image
                text_intro
                book_identifier
                show_email_box
              }
            }
            ... on PRISMIC_BookBodyTwo_cols{
              type
              label
              primary{
                left_text
                right_text
                right_text_footer
                button_label
                button_target {
                  _linkType
                  __typename
                }
              }
            }
            ... on PRISMIC_BookBodyLatest_posts {
              type
              label
              primary{
                show_thumbnails
              }
            }
            ... on PRISMIC_BookBodyBooks {
              type
              label
            }
            ... on PRISMIC_BookBodyBio {
              type
              label
              primary{
                bio_content
                button_label
                button_target{
                  ... on PRISMIC__Document{
                    _meta {
                      uid
                      type
                      lang
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    books:allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          cover
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }      
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default BookReadMore
