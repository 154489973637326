import React from 'react';
import pdfIcon from '../images/pdf_icon.svg';
import epubIcon from '../images/epub_icon.svg';
import audiobookIcon from '../images/audiobook_icon.svg';
import { Box, Image, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

const BookLinks = ({ links = {} }) => {
  const { pdf_link: pdfLink, epub_link: epubLink, audiobook_link: audiobookLink } = links;

  const linksToRender = [
    { url: pdfLink?.url, image: pdfIcon, type: "document" },
    { url: epubLink?.url, image: epubIcon, type: "document" },
    { url: audiobookLink?.url, image: audiobookIcon, type: "audio" },
  ];

  const existingLinks = linksToRender.filter(({ url }) => !!url);

  if (existingLinks.length === 0) {
    return null;
  }
  
  const documentLinks = existingLinks.filter(({ type }) => type === 'document');
  const mediaLinks = existingLinks.filter(({ type }) => type === 'audio');

  return (
    <Box display="flex" justifyContent="space-around" mb={5} flexWrap="wrap">
      { documentLinks && documentLinks.length > 0 ?
        documentLinks.map(({ image, url }) => (
          <Link style={{ boxShadow: 'none' }}
                href={url}
                target="_blank"
                mb={4}>
          <Image
            w={100}
            h={50}
            objectFit='cover'
            src={image}
          />
        </Link>
      ))
        : <></>
      }
      { mediaLinks && mediaLinks.length > 0 ?
        mediaLinks.map(({ image, url }) => (
          <GatsbyLink style={{ boxShadow: 'none' }} mb={4}
                      target="_blank"
                      to="/audio-book/"
                      state={{ source: url }}>
            <Image
              w={100} h={50}
              objectFit="cover"
              src={image}
            />
          </GatsbyLink>
        ))
        : <></>
      }
    </Box>
  );
};

export default BookLinks;
