import React, { useRef, useState } from 'react'
import {
    Box,
    Button,
    Flex,
    Grid,
    Image,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Heading,
    Stack,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga'
import BookLinks from './BookLinks';
ReactGA.initialize(process.env.GA_ID)
// import NewsletterForm from '../components/NewsletterForm'


const NewsletterModalBook = (props) => {
    const input = useRef()
    const intl = useIntl()


    const [isEmailRecord, setEmailRecord] = useState(false);
    const { book } = props

    const submit = (e) => {
        e.preventDefault()
        addToMailchimp(input.current.value,
            {
                BOOK: book.book_identifier,
                LANG: intl.locale
            }
        )
            .then(data => {
                ReactGA.event({
                    category: 'Newsletter',
                    action: 'Subscribe',
                    label: 'LpModal - ' + book.book_identifier
                });
                if (data.result === 'success') {
                    setEmailRecord(true)
                }
                if (data.result === 'error') {
                    setEmailRecord(true)
                }
            })
            .catch(() => {

            })
    }

    const ModalForm = () => {
        return (
            <Stack spacing='.5rem' as='form' onSubmit={submit}>
                <Text as='label'>
                    <FormattedMessage id='lp.email.label' />
                </Text>
                <Flex>
                    <Input
                        ref={input}
                        borderRadius='2px 0 0 2px'
                        name='email' type='email' required
                        _focus={{
                            borderColor: 'brand.orange'
                        }}
                    />
                    <Button
                        borderRadius='0 2px 2px 0'
                        border='solid 1px'
                        borderColor='brand.orange'
                        bg='white'
                        color='brand.orange'
                        fontFamily='Open Sans'
                        px='2rem'
                        type='submit'>Ok</Button>
                </Flex>

                <Text mt='.5rem' fontFamily='Open Sans' fontSize={{ base: '12px', lg: '13px' }}>
                    <FormattedMessage id='lp.security.notice' />
                </Text>

            </Stack>
        )
    }

    const SuccessMessage = () => {
        return (
          <>
            <Text mb={5} color="green.500">
              <FormattedMessage id="footer.subscrive_to_vitaly_newsletter_success" />
            </Text>
            <BookLinks links={props.bookLinks} />
          </>
        )
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.handleClose}
            initialFocusRef={input}

            size={{ base: '100vw', lg: '1000px' }}
            isCentered={{ base: false, lg: true }}

        >
            <ModalOverlay bg='rgba(255,255,255,0.9)' />
            <ModalContent
                background="white"
                my='0'

            >
                <ModalBody p='0' >
                    <Flex position='absolute' top='0' left='0' w='100%' justifyContent='flex-end' p='1rem'>
                        <ModalCloseButton />
                    </Flex>
                    <Grid templateColumns={{
                        base: '100%',
                        lg: ' 350px 1fr'
                    }}
                        gap={{ base: '1rem', lg: '2rem' }}
                    >
                        <Box
                            p={{ base: '0', lg: '3rem' }}
                            bg='gray.100'
                        >
                            <Image
                                display={{ lg: 'none' }}
                                w={{ base: '100%', lg: 'auto' }}
                                mx='auto'
                                objectFit='cover'
                                objectPosition='bottom'
                                src={book.cover_3d ? book.cover_3d.url : book.cover.url}
                                alt={book.cover.alt ? book.cover.alt : ''}
                                mb='0'
                                p='0'
                            />
                            <Image
                                display={{ base: 'none', lg: 'block' }}
                                w={{ base: '80%', lg: 'auto' }}
                                mx='auto'
                                objectFit='cover'
                                objectPosition='bottom'
                                src={book.cover.medium ? book.cover.medium.url : book.cover.url}
                                alt={book.cover.alt ? book.cover.alt : ''}
                            />
                            {/* <Box p='3rem' h='300px' w='200' bg='gray.100' /> */}
                        </Box>
                        <Stack spacing='.75rem' justifyContent='center' p={{ base: '1rem', lg: '3rem' }}>
                            <Box>
                                {!isEmailRecord ?
                                    <ModalForm />
                                    :
                                    <SuccessMessage />
                                }
                            </Box>

                        </Stack>
                    </Grid>
                    {/* <NewsletterForm /> */}
                </ModalBody>
            </ ModalContent>
        </Modal>
    )
}

export default NewsletterModalBook
